<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down font-weight-light" v-text="title" />

    <v-spacer />

    <v-text-field
      :label="$t('search')"
      color="secondary"
      v-model="searchText"
      hide-details
      style="max-width: 250px"
      v-if="searchFunction && searchParamString"
    >
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <div class="mx-3" />

    <v-btn class="ml-2" min-width="0" text to="/">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <!--    <v-menu bottom left offset-y origin="top right" transition="scale-transition">-->
    <!--      <template v-slot:activator="{ attrs, on }">-->
    <!--        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">-->
    <!--          <v-badge color="red" overlap bordered>-->
    <!--            <template v-slot:badge>-->
    <!--              <span>5</span>-->
    <!--            </template>-->

    <!--            <v-icon>mdi-bell</v-icon>-->
    <!--          </v-badge>-->
    <!--        </v-btn>-->
    <!--      </template>-->

    <!--      <v-list :tile="false" nav>-->
    <!--        <div>-->
    <!--          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">-->
    <!--            <v-list-item-title v-text="n" />-->
    <!--          </app-bar-item>-->
    <!--        </div>-->
    <!--      </v-list>-->
    <!--    </v-menu>-->

    <v-btn class="ml-2" min-width="0" text to="/user-profile">
      <v-icon>mdi-account</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from 'vuetify/lib';
import LodashMixin from '@/mixins/lodash';

// Utilities
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'DashboardCoreAppBar',
  mixins: [LodashMixin],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default,
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    initTitle: {
      type: String,
    },
    searchFunction: {
      type: Function,
    },
    searchParamString: {
      type: String,
    },
  },

  data: () => ({
    notifications: [
      'Mike John Responded to your email',
      'You have 5 new tasks',
      "You're now friends with Andrew",
      'Another Notification',
      'Another one',
    ],
    searchText: null,
    debounceSearch: null,
    disableDebounce: false,
  }),

  created() {
    if (this.searchParamString) {
      let query = this.$route.query;
      if (query[this.searchParamString]) {
        this.searchText = query[this.searchParamString];
        this.disableDebounce = true;
      }
    }
    this.debounceSearch = this.debounce(this.startSearch, 700);
  },

  watch: {
    searchText(val) {
      if (!this.disableDebounce) {
        this.debounceSearch(val);
      } else {
        this.disableDebounce = false;
      }
    },
  },

  computed: {
    ...mapState(['drawer']),
    title() {
      if (this.initTitle) {
        return this.initTitle;
      } else {
        return this.$route.name;
      }
    },
  },

  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),
    startSearch(val) {
      if (this.searchFunction && this.searchParamString) {
        let query = this.$route.query;
        query[this.searchParamString] = val;
        query.page = 1;
        this.searchFunction(query);
      }
    },
  },
};
</script>
